import React from 'react';
import { useTranslation } from 'react-i18next';

import { useBillingPayment } from '@util/providers/BillingPayment';
import { usePlan } from '@util/providers/Plan';
import { useInvoice } from '@util/providers/Invoice';
import useSubscription from '@util/hooks/useSubscription';

import ProtectedPage from '@components/ProtectedPage';
import Go from '@util/CustomRedirect';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';
import Checkmark from '@images/checkmarkInverted.svg';
import ThankYouBody from '../components/ThankYou/thankyou';

export default function ThankYou() {
  const { t } = useTranslation();
  const { billingDetails, paymentDetails } = useBillingPayment();
  return (
    <ProtectedPage>
      <Rules>
        <SEO title={t('page:thankYouForYourOrder')} />
        <PageHeader className="mb-4">
          {t('page:thankYouForYourOrder')}
          <img className="pl-3" src={Checkmark} alt="yes" />
        </PageHeader>
        <ThankYouBody
          billingDetails={billingDetails}
          paymentDetails={paymentDetails}
        />
      </Rules>
    </ProtectedPage>
  );
}

function Rules({ children }) {
  const { selectedRate, selectedPlan } = usePlan();
  const { isActiveSubscriber, isTrialUser } = useSubscription();
  const { billingDetails, paymentDetails } = useBillingPayment();
  const { invoicePreview, invoiceNumber, invoiceId } = useInvoice();
  const homeRules =
    !selectedPlan ||
    !selectedRate ||
    !billingDetails ||
    !paymentDetails ||
    !invoicePreview ||
    (isActiveSubscriber && !isTrialUser) ||
    !invoiceNumber ||
    !invoiceId ||
    selectedRate.isTrial ||
    (selectedPlan.key !== 'personal' && selectedPlan.key !== 'professional');
  return (
    <Go to="/" when={homeRules}>
      {children}
    </Go>
  );
}
